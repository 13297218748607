<template>
    <!-- 头部 -->
      <div class="header">
        <div class="text">
          <img src="@/assets/logo.png" width="47px" height="47px" />
        </div>
        <div class="name">
          <div class="shjc">山 海 景 藏</div>
        </div>
      </div>
</template>

<script>
export default {};
</script>

<style lang='less' scoped>
   .header {
      //   background-image: linear-gradient(#ffc400, #fff);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 20px 20px 20px;
      .text {
        font-size: 20px;
        font-weight: 600;
      }
      .name {
        width: 100%;
        .shjc {
          display: flex;
          align-items: center; //交叉轴
          justify-content: center; //主线

          font-size: 17px;
          color: white;
        }
      }
    }
</style>