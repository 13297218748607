<template>
  <div class="mine">
    <!-- <Header /> -->
    <div class="content" @click="showPopup">
      <div class="user-info" @click="togo('./login')">
        <div class="info">
          <img src="@/assets/header.png" />
          <div class="user-desc">
            <span class="name">{{ nickName }}</span>
            <span class="phone">{{ phone }}</span>
          </div>
        </div>
      </div>
      <mine-option />
      <div class="auth_info_before" v-if="showFlag">
        <img src="@/assets/images/mine/block.svg" />
        <span>尚未实名认证</span>
        <span>实名认证后才能正常购买并体验数字版权</span>
        <div class="auth">
          <span @click="showAuth">实名认证</span>
        </div>
      </div>
      <name-auth v-if="showAuthFlag" @close="closePopup" />
      <!-- v-if="showGoodsFlag" -->
      <mine-goods v-if="showGoodsFlag" />

      <div class="bottom-img">
        <img class="xbl-img" src="../../assets/xbl.svg" />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import { Popup, Button, Toast } from "vant";
import { ref } from "vue";
import NameAuth from "@/pages/mine/components/name-auth";
import MineGoods from "@/pages/mine/mine-goods";
import MineOption from "@/pages/mine/mine-option";

export default {
  name: "mine",
  components: {
    Footer,
    Header,
    NameAuth,
    MineGoods,
    MineOption,
  },
  setup() {
    const logInfo = window.sessionStorage.getItem("userOpenid");
    const showAuthFlag = ref(false);
    const showGoodsFlag = ref(false);
    const showFlag = ref(true);
    const nickName = ref("");
    const phone = ref("");
    const router = useRouter();
    //路由跳转
    const togo = (path) => {
      const userOpenid = JSON.parse(window.sessionStorage.getItem("userOpenid"));
      if (userOpenid && userOpenid.mobilex) {
        Toast("已登录");
        return;
      }
      router.push(path);
    };
    return {
      togo,
      showAuthFlag,
      showGoodsFlag,
      showFlag,
      nickName,
      phone,
    };
  },
  beforeRouteEnter(to, from, next) {
    if ((!from.fullPath.includes("/userGoodsSingle")) && (!from.fullPath.includes("/userGoodsSeries"))) {
      next((vm) => {
        vm.initPage();
      });
    } else {
      const scrollTop = window.sessionStorage.getItem("mineScollTop") || 0;
      next(() => {
        document.querySelector(".content").scrollTo(0, scrollTop);
      });
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    // 如果不是到mine页面，清除mineScollTop，否侧设置
    if ((!to.fullPath.includes("/userGoodsSingle")) && (!to.fullPath.includes("/userGoodsSeries"))) {
      sessionStorage.setItem("mineScollTop", 0);
    } else {
      const scrollTop = document.querySelector(".content").scrollTop;
      window.sessionStorage.setItem("mineScollTop", scrollTop);
    }
    next();
  },

  methods: {
    initPage() {
      this.showGoodsFlag = false;
      setTimeout(() => {
        const userOpenid = JSON.parse(window.sessionStorage.getItem("userOpenid"));
        if (userOpenid && userOpenid.mobilex) {
          this.nickName = userOpenid.nickname;
          this.phone = userOpenid.mobilex;
        } else {
          this.nickName = "请登录";
          this.phone = "";
        }
        if (userOpenid && userOpenid.haveIc) {
          this.showFlag = false;
          this.showGoodsFlag = true;
        } else {
          this.showFlag = true;
          this.showGoodsFlag = true;
        }
      });
    },
    showAuth() {
      const userOpenid = JSON.parse(window.sessionStorage.getItem("userOpenid"));
      if (userOpenid.mobile === null) {
        Toast("请先登录");

        return;
      }
      this.showAuthFlag = true;
    },
    closePopup() {
      this.showAuthFlag = false;
      this.initPage();
    },
  },
  created() {
    this.initPage();
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-button--danger {
  background-color: #ffc400;
  border-color: #ffc400;
}
.van-cell {
  background: rgba(33, 30, 32, 1);
  color: rgba(255, 255, 255, 1);
}
.van-field__control {
  color: rgba(255, 255, 255, 1);
}
input.van-field__control::-webkit-input-placeholder {
  color: #4d6ff0;
}
.save-btn {
  width: 80%;
  margin: 20px auto;
}
.mine {
  display: flex;
  flex-flow: column;
  background: rgba(33, 30, 32, 1);
  height: 100%;
  overflow: auto;
  .content {
    flex: 1;
    overflow: auto;
    .user-info {
      width: 94%;
      margin-left: 10px;
      height: 115px;
      .info {
        position: relative;
        display: flex;
        width: 100%;
        height: 100px;
        box-sizing: border-box;
        img {
          padding: 15px 20px;
          border-radius: 50%;
          margin-top: 4px;
          width: 66px;
          height: 66px;
        }
        .user-desc {
          display: flex;
          flex-direction: column;
          line-height: 20px;
          color: #fff;
          justify-content: center;
          .name {
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
          }
          .phone {
            color: rgba(128, 128, 128, 1);
            font-size: 10px;
          }
        }
        .account-setting {
          position: absolute;
          top: 10px;
          right: 20px;
          font-size: 13px;
          color: #fff;
          .van-icon-setting-o {
            font-size: 16px;
            vertical-align: -3px;
            margin-right: 4px;
          }
        }
      }
    }
    .auth_info_before {
      width: 9.467rem;
      margin: auto;
      margin-top: 0.693rem;
      height: 5.52rem;
      display: flex;
      flex-flow: column nowrap;
      justify-content: start;
      align-items: center;
      position: relative;
      background: rgba(50, 50, 50, 1);
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      img {
        position: relative;
        margin-top: 0.587rem;
        width: 0.64rem;
        height: 0.56rem;
      }
      span:nth-of-type(1) {
        position: relative;
        text-align: center;
        margin-top: 24px;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
      }
      span:nth-of-type(2) {
        position: relative;
        text-align: center;
        margin-top: 0.107rem;
        color: rgba(131, 131, 131, 1);
        font-size: 0.373rem;
      }
      .auth {
        position: relative;
        width: 100px;
        height: 35px;
        margin-top: 25px;
        background: linear-gradient(
          90deg,
          rgba(143, 105, 59, 1) 0%,
          rgba(217, 173, 115, 1) 100%
        );
        border-radius: 17.5px;
        display: flex;
        span {
          position: relative;
          text-align: center;
          margin: auto;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
        }
      }
    }
    .bottom-img {
      margin-top: 40px;
      margin-bottom: 60px;
      text-align: center; /* 水平 */
      .xbl-img {
        width: 127px;
        height: 18px;
        text-align: center;
      }
    }
  }
}
</style>
