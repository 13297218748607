<template>
  <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text=""
    @load="onLoad"
  >
    <div class="store_list">
      <div v-for="(i, index) in list" :key="index" class="store_list_item">
        <BuyGoodsItem :item="i" />
      </div>
    </div>
  </van-list>
</template>

<script>
import BuyGoodsItem from "./BuyGoodsItem.vue";
import { ref } from "vue";
import { Toast } from "vant";
import axios from "@/request/request";

export default {
  props: ["store_list"],
  components: {
    BuyGoodsItem,
  },
  setup() {
    return {

    };
  },
  mounted() {
    this.list = []
    this.page = 1
  },
  data() {
   return {
     list: [],
     loading: false,
     page:1,
     finished:false
   }
  },
  methods: {
   onLoad() {
      const userOpenid = JSON.parse(
        window.sessionStorage.getItem("userOpenid")
      );
      var _this = this;
      if(userOpenid != null){
        axios
        .post("app/getUserGoodsPolymerize", {
          id: userOpenid.id,
          buyType: "ALL",
          page: _this.page,
          limit: 10,
        })
        .then(function (res) {
          if (res.status === 200 && res.data.retCode === 0) {
            let data = res.data.data.data;
            if (data) {
              data.forEach((item) => {
                _this.list.push(item);
              });
              _this.page+=1
              Toast.clear();
              // 数据全部加载完成
              _this.loading = false;
              if (_this.list.length >= res.data.data.count) {
                _this.finished = true;
              }
            }
          } else {
            Toast.fail("获取数据失败" + res.data.retMsg);
          }
        })
        .catch(function (error) {
          Toast.fail("请检查网络" + error);
        });
      }
      
     
    }
  }
};
</script>

<style lang='less' scoped>
.store_list {
  display: grid;
  grid-template-rows: repeat(4);
  grid-template-columns: repeat(2, 1fr);
  margin: auto;
  background: rgba(33, 30, 32, 1);
  .store_list_item {
    margin-top: 10px;
    margin-left: 10px;
    // margin-right: 10px;
  }
}
</style>