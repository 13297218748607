<template>
    <div class="user-menu">
    <div class="menu">
        <div class="user-menu"  @click="togo('./order')">
          <div class="menu-img">
              <img src="@/assets/order.svg" />
          </div>
          <div class="order_text">我的订单</div>
        </div>
        <div class="split-line">
        <img src="@/assets/split_line.png" />
        </div>
        <div class="user-menu" @click="togo('./record')">
          <div class="menu-img">
              <img src="@/assets/transaction_record.svg" />
          </div>
          <div class="order_text">交易记录</div>
        </div>

        <div class="split-line">
        <img src="@/assets/split_line.png" />
        </div>
        <div class="user-menu"  @click="togo('./setting')">
          <div class="menu-img">
              <img src="@/assets/setting.svg" />
          </div>
          <div class="order_text">设置</div>
        </div>
    </div>
    </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
    setup() {
    //路由跳转
    const router = useRouter();
    const togo = (path) => {
      router.push(path);
    };
    return {
       togo
    };
    },
}
</script>

<style lang='less' scoped>
.user-menu {
      width: 94%;
      margin: auto;
      height: 1.76rem;
      background: rgba(50, 50, 50, 1);
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      .menu {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        .user-menu {
          display: flex;
          justify-content: center;
          flex-flow: column;
          align-items: center;
          width: 33%;
          color: rgba(131, 131, 131, 1);
          font-size: 10px;
          .menu-img {
            width: 24px;
            height: 24px;
          }
        }
        .split-line {
          width: 0px;
          height: 50px;
          margin: auto;
          background: rgba(166, 166, 166, 1);
          border: 0.5px solid rgba(166, 166, 166, 0.8);
        }

        .user-desc {
          display: flex;
          flex-direction: column;
          line-height: 20px;
          color: #fff;
          justify-content: center;
          .name {
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
          }
          .phone {
            color: rgba(128, 128, 128, 1);
            font-size: 10px;
          }
        }
        .account-setting {
          position: absolute;
          top: 10px;
          right: 20px;
          font-size: 13px;
          color: #fff;
          .van-icon-setting-o {
            font-size: 16px;
            vertical-align: -3px;
            margin-right: 4px;
          }
        }
      }
    }
</style>
