<template>
  <div>
    <van-popup
      v-model:show="nameAuthShow"
      position="center"
      @click-overlay="closePopup"
      @close="closePopup"
      :overlay-style="{ opacity: 0.2}"
      round="true"
      color="rgba(33, 30, 32, 1)"
    >
      <div class="vpopup">
        <div class="popup">
          <div class="title">实名认证</div>
          <div class="info">
            实名认证为数字版权确权需要，该实名认证将建立与购买数字版权作品对应的法律关系，将保护您所购买的数字版权作品的对应权益
          </div>
          <div class="input-item">
            <div class="item">
              <van-cell-group inset>
                <van-field v-model="name" placeholder="真实姓名:请点击填写" />
              </van-cell-group>
            </div>
            <div class="item">
              <van-cell-group inset>
                <van-field v-model="idNo" placeholder="身份证号:请点击填写" />
              </van-cell-group>
            </div>
            <div class="item">
              <van-cell-group inset>
                <van-field v-model="mobile" placeholder="手机号:请点击填写" />
              </van-cell-group>
            </div>
            <div class="item">
              <van-cell-group inset>
                <van-field
                  v-model="msg"
                  placeholder="验证码"
                  label="验证码"
                   maxlength="4"
                  clearable
                  center
                >
                  <template #button>
                    <div class="yzm">
                      <button
                        class="sendSms"
                        @click="sendSmsCode"
                        :disabled="isSmsSend"
                      >
                        {{ sendBtnText }}
                      </button>
                    </div>
                  </template>
                </van-field>
              </van-cell-group>
            </div>
          </div>
          <div class="save" @click="icAuth">
            <div class="info">提交认证信息</div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { ref } from "vue";
import { Toast } from "vant";
import { reactive, toRefs } from "vue";
import axios from "@/request/request";
import { useRouter } from "vue-router";
export default {
  setup() {
     const router = useRouter();
    const nameAuthShow = ref(true);
    let data = reactive({
      mobile: "",
      msg: "",
      idNo: "",
      name: "",
      isSmsSend: false,
      sendBtnText: "获取验证码",
      timer: null,
      counter: 60,
       exData: {},
    });

    return {
       router,
      nameAuthShow,
      ...toRefs(data),
    };
  },
  methods: {
    reset() {
      this.isSmsSend = false;
      this.sendBtnText = "获取验证码";
      if (this.timer) {
        clearInterval(this.timer);
        this.counter = 60;
        this.timer = null;
      }
    },
    closePopup() {
      this.$emit("close");
  
    },
    sendSmsCode(event) {
      if (!this.mobile) {
        Toast("请输入手机号");
        return false;
      }
      if (this.mobile.length !== 11) {
        Toast("请输入11位手机号");
        return false;
      }
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      var THIS = this;
      axios
        .post("app/sendVerificationCode", {
          mobile: this.mobile,
        })
        .then(function (res) {
          if (res.status === 200 && res.data.retCode === 0) {
            Toast.clear();
            THIS.isSmsSend = true;
            THIS.countDown();
          } else {
            Toast.fail("获取验证码失败" + res.data.retMsg);
            THIS.reset();
          }
        })
        .catch(function (error) {
          // 请求失败处理
          Toast.fail("请检查网络");
        });
    },
    countDown() {
      this.timer = setInterval(() => {
        this.sendBtnText = `重新发送(${this.counter}s)`;
        this.counter--;
        if (this.counter < 0) {
          this.reset();
        }
      }, 1000);
    },

    /*身份认证*/
    icAuth() {
      if (!this.mobile) {
        Toast("请输入手机号");
        return 
      }
      if (this.mobile.length !== 11) {
        Toast("请输入11位手机号");
        return 
      }
      if(!this.name) {
        Toast("请输入真实在姓名");
        return   
      }
      if(!this.idNo) {
        Toast("请输入身份证号");
        return   
      }
      if(!this.msg) {
        Toast("验证码不能为空");
        return   
      }
      Toast.loading({
        message: "认证中...",
        forbidClick: true,
      });
      const userOpenid = JSON.parse(
        window.sessionStorage.getItem("userOpenid")
      );
      var THIS = this;
      axios
        .post("app/icAuthentication", {
          mobile: this.mobile,
          id: userOpenid.id,
          openid: userOpenid.openid,
          msg: this.msg,
          name: this.name,
          idNo: this.idNo
        })
        .then(function (res) {
          if (res.status === 200 && res.data.retCode === 0) {
            let data = res.data.data
            window.sessionStorage.setItem("userOpenid", JSON.stringify(data))
            window.localStorage.setItem("userOpenid", JSON.stringify(data));

             Toast.success("身份验证成功");
              if(res.data.exData){
                 THIS.router.push({ path: "./shareGoodsInfo", query: { state: res.data.exData.sp } });
            }
            THIS.closePopup()
          } else {
            Toast.fail("身份信息认证失败" + res.data.retMsg);
          }
        }).catch(function (error) {
          // 请求失败处理
          Toast.fail("请检查网络");
        });
    },
  },
  created() {
    
  },
};
</script>

<style lang="less" scoped>
.vpopup {
  width: 350px;
  z-index: 9999;
  background: rgba(33, 30, 32, 1);
  .popup {
    background: rgba(33, 30, 32, 1);
    padding-bottom: 50px;
    .title {
      padding-top: 40px;
      text-align: center;
      color: rgba(255, 255, 255, 1);
      font-size: 16px;
    }
    .info {
      color: rgba(131, 131, 131, 1);
      font-size: 14px;
      padding: 20px;
    }
    .input-item {
      .item {
        padding: 10px;
      }
    }
    .van-cell {
      background: rgba(50, 50, 50, 1);
    }
    /deep/ .van-field__control {
      color: #f2f2f6;
    }
    /deep/ .van-field__error-message {
      color: #f5f5f5;
      font-size: var(--van-field-error-message-font-size);
      text-align: left;
    }

    /deep/ .van-field__label--required:before {
      margin-right: 2px;
      color: #f5f5f5;
      content: "*";
    }
    /deep/ .van-cell-group {
      background: #00000000;
    }
    .yzm {
      color: #fff;
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
       font-size: 11px;
      .sendSms {
        width: 100px;
        background: #00000000;
        overflow-x: hidden;
      }
    }
    .yzm:before {
      content: "|";
      display: inline-block;
      color: #fff;
    }
    .save {
      position: relative;
      margin-top: 40px;
      .info {
        width: 150px;
        height: 30px;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        line-height: 30px;
        background: linear-gradient(
          90deg,
          rgba(143, 105, 59, 1) 0%,
          rgba(217, 173, 115, 1) 100%
        );
        border-radius: 20px;
        padding: 5px 20px 5px 20px;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }
  }
}
</style>
