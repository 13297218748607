<template>
  <div class="footer">
    <div class="item">
      <router-link to="/home" class="nav_item1" active-class="nav_color">
        <van-icon
          class="iconfont"
          class-prefix="icon"
          name="shouye"
        />
        <!-- <i class="iconfont icon-shouye" size="40"></i>  -->
        首页
      </router-link>
    </div>

    <div class="item">
      <router-link to="/mine" class="nav_item2" active-class="nav_color" @click.native="showRouter">
        <!-- <van-icon name="user-circle-o" size="35" /> -->
        <van-icon
          class="iconfont"
          class-prefix="icon"
          name="wodefill"
        />
        我的
      </router-link>
    </div>
  </div>
  <!-- <div class="footer-replace"></div> -->
</template>

<script>
export default {
  
    inject: [
    'reload'
  ],
  methods: {
      showRouter () {
      this.reload()
    }
  }
};
</script>

<style lang='less' scoped>
.footer {
  display: flex;
  font-size: 12px;
  background: rgba(33, 30, 32, 1);
  border-top: 1px solid #323232;
  height: 54px;
  // position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99999;
  width: 100%;
  .item {
    flex: 1;
    display: flex;
    justify-content: center;

    position: relative; /*脱离文档流*/
    align-items: center;
    .nav_color {
      color: white;
    }
    .nav_item1 {
      display: flex;
      flex-flow: column;
      align-items: center;
      position: relative;
      left: 10%; /*偏移*/
    }
    .nav_item2 {
      display: flex;
      flex-flow: column;
      align-items: center;
      position: relative;
      right: 10%; /*偏移*/
    }
  }
  .iconfont {
    font-size: 24px;
  }
}
.footer-replace {
  height: 54px;
}
</style>